// Start with one letter. For each adjacent letter which has not been used before, check if appending that letter forms a viable word.
// then repeat from that next letter.

import { checkWord } from ".";
import { wordList } from "./dictionary/words";

const checkWordList = wordList;

export const checkNextLetters = (wordGrid, currentWord, usedLetters, column, row, availableWords, maxWordLength = 6) => {
    let placementOptions = [[0, -1], [1, 0], [1, -1], [-1, 0], [-1, -1], [1, 1], [0, 1], [-1, 1]];
    // remove placement options that correspond to missing letters or used letters
    for (let i = placementOptions.length-1; i >= 0; i--) {
        let option = placementOptions[i];
        let letterColumn = wordGrid[column + option[0]];
        if (letterColumn == undefined || letterColumn[row + option[1]] == undefined || usedLetters[column + option[0]][row + option[1]] == 1) {
            placementOptions.splice(i, 1);
        }
    }

    if (placementOptions.length > 0) {
        for (let i = 0; i < placementOptions.length; i++) {
            let option = placementOptions[i];
            let nextColumn = column + option[0];
            let nextRow = row + option[1];
            let nextLetter = wordGrid[nextColumn][nextRow];
            let nextWord = currentWord + nextLetter;
            let nextUsedLetters;

            if (checkWord(nextWord)) {
                nextUsedLetters = usedLetters.map((x) => [...x]);
                nextUsedLetters[nextColumn][nextRow] = 1
                let availableWord = {
                    word: nextWord,
                    usedLetters: nextUsedLetters
                }
                availableWords.push(availableWord);
                if (nextWord.length > 7) {
                    console.log("Found word: " + nextWord);
                }
            }
            if (nextWord.length < maxWordLength) {
                if (nextUsedLetters == undefined) {
                    nextUsedLetters = usedLetters.map((x) => [...x]);
                    nextUsedLetters[nextColumn][nextRow] = 1
                }
                checkNextLetters(wordGrid, nextWord, nextUsedLetters, nextColumn, nextRow, availableWords, maxWordLength);
            }
        }
    }
}

export const checkWordGrid = (wordGrid, maxWordLength) => {
    let usedLetters = [];
    let availableWords = [];

    for (let i = 0; i < wordGrid.length; i++) {
        usedLetters.push([]);
    }

    for (let i = 0; i < wordGrid.length; i++) {
        let column = wordGrid[i];
        for (let j = 0; j < column.length; j++) {
            let startingWord = wordGrid[i][j];
            let nextUsedLetters = usedLetters.map((x) => [...x]);
            nextUsedLetters[i][j] = 1;
            checkNextLetters(wordGrid, startingWord, nextUsedLetters, i, j, availableWords, maxWordLength);
        }
    }

    return availableWords;
}

export const checkSolutionChain = (chain, letterGrid, completeChains, wordCheckLimit, maxWordLength) => {
    // if the chain contains an entry, use the last one to delete letters from the grid for the next check.

    let nextLetterGrid = letterGrid;
    if (chain.length > 0) {
        const availableWord = chain[chain.length-1];
        const usedLetters = availableWord.usedLetters;
        nextLetterGrid = letterGrid.map((x) => [...x]);

        for (let i = usedLetters.length-1; i >= 0; i--) {
            let column = usedLetters[i];
            for (let j = column.length; j >= 0; j--) {
                if (column[j] == 1) {
                    nextLetterGrid[i].splice(j, 1);
                }
            }
            if (nextLetterGrid[i].length == 0) {
                nextLetterGrid.splice(i, 1);
            }
        }
    }

    let tileCount = 0;
    for (let i = nextLetterGrid.length-1; i >= 0; i--) {
        tileCount += nextLetterGrid[i].length
    }

    let availableWords = checkWordGrid(nextLetterGrid, maxWordLength);
    availableWords.sort((a, b) => {
        return b.word.length - a.word.length;
    });

    let checkLimit = Math.min(availableWords.length, wordCheckLimit);
    for (let i = 0; i < checkLimit; i++) {
        const availableWord = availableWords[i];

        const tilesRemaining = tileCount - availableWord.word.length;
        if (tilesRemaining == 1) {
            // this chain failed.
        } else {
            // clone the chain into a new chain.
            let nextChain = [...chain, availableWord];

            if (tilesRemaining == 0) {
                // this is a successful chain!
                completeChains.push(nextChain);
            } else {
                checkSolutionChain(nextChain, nextLetterGrid, completeChains, wordCheckLimit, maxWordLength);
            }
        }
    }
}
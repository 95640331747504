function Tile(letter, originalColumn, originalRow) {
    this.letter = letter;
    this.originalColumn = originalColumn;
    this.originalRow = originalRow;
    this.column = originalColumn;
    this.row = originalRow;
    this.id = "tile" + String(this.originalColumn) + "-" + String(this.originalRow);

    this.left = 0;
    this.bottom = 0;
    this.width = 50;
}

Tile.prototype.generateHTML = function () {
    return "<div class='tile' id='" + this.id + "'><div class='tileCentre'></div><div class='letter-wrapper'>" + this.letter.toUpperCase() + "</div></div>"
};

Tile.prototype.snapshot = function () {
    return {
        l : this.letter,
        oX : this.originalColumn,
        oY : this.originalRow,
        x : this.column,
        y : this.row
    }
};

export default Tile;
import Tile from './tile';
import './wordgame.css'
import successAudio from './audio/success.m4a'
import letterAudio1 from './audio/1.m4a'
import letterAudio2 from './audio/2.m4a'
import letterAudio3 from './audio/3.m4a'
import letterAudio4 from './audio/4.m4a'
import letterAudio5 from './audio/5.m4a'
import letterAudio6 from './audio/6.m4a'
import letterAudio7 from './audio/7.m4a'
import letterAudio8 from './audio/8.m4a'
import { wordList } from './dictionary/words';
import { checkNextLetters, checkSolutionChain, checkWordGrid } from './solver';
import { performance } from 'universal-perf-hooks'
import { seedList } from './seeds';
import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'
import { getFirestore, collection, doc, setDoc, query, where, getDocs, Timestamp, getCountFromServer, } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCHXb9uAkXvxpGP_g82smxSJMKEPm8hfNs",
    authDomain: "wordago-a35ee.firebaseapp.com",
    projectId: "wordago-a35ee",
    storageBucket: "wordago-a35ee.appspot.com",
    messagingSenderId: "398238694222",
    appId: "1:398238694222:web:2aede4c711b72e5a817c1f",
    measurementId: "G-6EQ9RWTTBT"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

var gen = require('random-seed'); // create a generator
var seed = '';
var rng;

let currentSeedIndex = 0;

$('#seed-input').val(seed);

const letterCounts = [
    ['E', 24],
    ['T', 18],
    ['A', 16],
    ['I', 16],
    ['O', 16],
    ['N', 16],
    ['S', 16],
    ['H', 13],
    ['R', 12],
    ['D', 9],
    ['L', 8],
    ['U', 7],
    ['C', 6],
    ['M', 6],
    ['F', 5],
    ['W', 4],
    ['Y', 4],
    ['G', 3],
    ['P', 3],
    ['B', 3],
    ['V', 2],
    ['K', 2],
    ['Q', 1],
    ['J', 1],
    ['X', 1],
    ['Z', 1],
]

const vowels = ['A', 'E', 'I', 'O', 'U'];

let currentScore = 0;
const wordScoreChart = {
    2 : 1,
    3 : 2,
    4 : 4,
    5 : 7,
    6 : 10,
    7 : 14,
    8 : 18,
    9 : 23,
    10 : 28,
    11 : 33,
    12 : 38
};
const scorePenaltyPerTile = 1;
const perfectClearBonus = 6;

// https://www3.nd.edu/~busiforc/handouts/cryptography/letterfrequencies.html
// 12,000	E	2,500	F
// 9,000	T	2,000	W, Y
// 8,000	A, I, N, O, S	1,700	G, P
// 6,400	H	1,600	B
// 6,200	R	1,200	V
// 4,400	D	800	K
// 4,000	L	500	Q
// 3,400	U	400	J, X
// 3,000	C, M	200	Z

let _availableLetters = [];

letterCounts.forEach(pair => {
    let letter = pair[0];
    let count = pair[1];
    for (let i = 0; i < count; i++) {
        _availableLetters.push(letter);
    }
});

let _availableLetterCount = _availableLetters.length;

let letterGrid ;
let tiles = [];
let tileIdMap = {};
let currentChain = [];
let currentWord = '';
let wordIsValid = false;
let history = [];
let replayHistory;

let turnsTaken = 0;
let tilesLeft = 0;
let gameComplete = false;
let undoUsed = true;
let attemptNumber = 0;

let mouseDown = false;
let touchDown = false;

let letterContainer = $('#letter-container');
let currentWordLabel= $('#current-word-label');
let currentWordContainer = $('#current-word-container');

// check query string for history
let urlParams = new URLSearchParams(window.location.search);
let h = urlParams.get('h');
let s = urlParams.get('s');
if (h && s) {
    seed = s;
    replayHistory = decodeHistory(h);
}

var startDate = new Date(2022,7,15,0,0,0,0);
function getSeedForDate(date) {
    let fromDate = new Date(date).setHours(0,0,0,0);
    let timeDiff = fromDate - startDate;
    return Math.round(timeDiff / 864e5) - 1
}

function updateTileLetters() {
    clearBoard();

    if (seed.length == 0) {
        var today = new Date();
        seed = String(getSeedForDate(today));

        let lastSeed = localStorage.getItem('lastSeed');

        if (lastSeed != seed) {
            // First time with this seed. Log it as a new game
            gtag('event', 'new_game');
            localStorage.setItem('lastSeed', seed);
        }
    }
    // loadCurrentSeedFromList();

    rng = gen.create(seed);
    letterGrid = [[], [], [], [], []];

    // testNext1000Puzzles();

    let savedGame;
    let oldGameLoaded = false;
    if (replayHistory) {
        gameComplete = true;
        oldGameLoaded = true;
        // create the letter grid from the seed and populate the letters in the history
        letterGrid = generateLetterGrid();
        turnsTaken = replayHistory.length;
        let tilesUsed = 0;
        for (let i = 0; i < replayHistory.length; i++) {
            let wordHis = replayHistory[i];
            for (let j = 0; j < wordHis.length; j++) {
                let letterHis = wordHis[j];
                letterHis.l = letterGrid[letterHis.oX][letterHis.oY];
            }
            tilesUsed += wordHis.length;
            currentScore += wordScoreChart[Math.min(wordHis.length, 12)];
        }
        if (tilesUsed == 25) {
            currentScore += 6;
        }
        history = replayHistory;
    } else {
        // first check if we have a saved game
        savedGame = loadGame();
    }

    if (savedGame != undefined && savedGame.seed != undefined) {
        try {
            let oldGameComplete = localStorage.getItem('gameComplete') == 'true';
            if (savedGame.seed == seed) {
                letterGrid = savedGame.letterGrid;
                turnsTaken = savedGame.turnsTaken;
                oldGameLoaded = true;
                gameComplete = oldGameComplete;
                history = savedGame.history;
                currentScore = savedGame.currentScore;
                undoUsed = savedGame.undoUsed;
                attemptNumber = savedGame.attemptNumber;
                
                $('#undoBtn').css({
                    'opacity': undoUsed ? '0.5' : 1.0,
                    'pointer-events': undoUsed ? 'none' : 'all'
                });
            } else {
                if (oldGameComplete) {
                    // check if the last seed was more than 1 day ago. If it is, we've missed a day and the current streak resets to 0
                    if (seed - savedGame.seed > 1) {
                        let stats = getStatistics();
                        stats.currentStreak = 0;
                        localStorage.setItem('statistics', JSON.stringify(stats));
                    }
                } else {
                    if (savedGame.attemptNumber == 0) {
                        // the game was not completed. Update the scores with this as a fail.
                        addLossToScoreSheet();
                    }
                }
            }
        } catch (error) {
            console.warn("Failed to load previous game");
        }
    }

    if (!oldGameLoaded) {
        gameComplete = false;
        turnsTaken = 0;
        currentScore = 0;
        letterGrid = generateLetterGrid();
        $('#blocking-view').remove();
    }

    tilesLeft = 0;
    for (let i = 0; i < letterGrid.length; i++) {
        let chosenLettersColumn = letterGrid[i];
        let column = [];
        for (let j = 0; j < chosenLettersColumn.length; j++) {
            let letter = letterGrid[i][j];
            let tile = addTile(letter, i, j, (i+j+1) * 100);
            column.push(tile);
        }
        tiles.push(column);
    }

    setupTileListeners();

    calculateTilePositions();
    updateTilePositions(false);

    updateScore();

    let initialDelay = 1000;
    let wordDelay = 500;
    let letterDelay = 75;
    let layoutDelay = 400;
    let highlightDelay = 0;

    if (replayHistory) {
        initialDelay = 2000;
        wordDelay = 1000;
        letterDelay = 300;
        layoutDelay = 1000;
        highlightDelay = 200;
    }

    let delay = initialDelay;
    if (oldGameLoaded) {
        if (history.length > 0 && !gameComplete) {
            Toastify({text: "Resuming previous game.", position: "center"}).showToast();
        }

        // reiterate history quickly.
        let chainsToRemove = [];
        for (let i = 0; i < history.length; i++) {
            const wordHistory = history[i];
            
            let chain = [];
            for (let i = 0; i < wordHistory.length; i++) {
                const letterHistory = wordHistory[i];
                chain.push(tiles[letterHistory.oX][letterHistory.oY].element[0]);
            }
            chainsToRemove.push(chain);
        }

        for (let i = 0; i < chainsToRemove.length; i++) {
            const chain = chainsToRemove[i];
            // setTimeout(() => {
                for (let j = 0; j < chain.length; j++) {
                    delay += highlightDelay;
                    setTimeout(() => {
                        const element = chain[j];
                        $(element).addClass('valid');
                    }, delay);
                }
                delay += wordDelay;
                setTimeout(() => {
                    removeWord(chain, false, letterDelay);
                }, delay);

                delay += letterDelay * chain.length;
                setTimeout(() => {
                    calculateTilePositions();
                    updateTilePositions(true);
                }, delay);
            // }, delay);
            // delay += (chain.length) * (letterDelay + highlightDelay) + wordDelay;
            tilesLeft -= chain.length;
        }
        updateScore();

        setTimeout(() => {
            $('#blocking-view').remove();
        }, delay);
    }

    if (gameComplete) {
        setTimeout(() => {
            showStatisticsModal();
        }, delay);
    }

    if (getBestToday() == null) {
        $('#viewStatsBtn').css({
            'opacity': '0.5',
            'pointer-events': 'none'
        });
    }
}

function addTile(letter, row, index, animateDelay) {
    
    let tile = new Tile(letter, row, index);   
    let tileElement = $(tile.generateHTML());   
    letterContainer.append(tileElement);
    tile.element = tileElement;
    tileIdMap[tile.id] = tile;
    tilesLeft++;

    if (animateDelay) {
        tileElement.css({
            'opacity': 0,
            'transition-duration': '0ms',
            'transform': 'scale(1.3)',
        });
        tileElement.css({
            'transition-duration': '',
        });
    
        setTimeout(() => {
            $( tileElement ).css({
                'transform': '',
            });
            $( tileElement ).animate({
                opacity: 1,
            }, 300);
        }, animateDelay);
    }

    return tile;
}

function generateLetterGrid() {
    let chosenLetters = [[], [], [], [], []];
    let vowelCount = 0;
    let letterCount = {};
    for (const [key, value] of Object.entries(letterCounts)) {
        letterCount[value[0]] = 0;
    }

    let availableLetters = [..._availableLetters];
    let availableLetterCount = _availableLetterCount;

    for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
            let index = rng(availableLetterCount);
            let letter = availableLetters[index];
            // remove the letters from the available letters array to prevent us getting too many 'Q's for example.
            availableLetters.splice(index, 1);
            availableLetterCount--;
            if (vowels.indexOf(letter) != -1) {
                vowelCount++;
            }
            letterCount[letter] += 1;
            chosenLetters[i][j] = letter;
        }
    }

    if (letterCount['Q'] > letterCount['I']) {
        chosenLetters[rng(5)][rng(5)] = 'I';
    }

    // make sure there are at least 10 vowels in the grid by replacing random tiles until we are at 10
    while (vowelCount < 10) {
        let column = rng(5);
        let row = rng(5);

        let existingLetter = chosenLetters[column][row];

        if (vowels.indexOf(existingLetter) == -1) {
            chosenLetters[column][row] = vowels[rng(5)];
            vowelCount++;
        }
    }

    return chosenLetters;
}

function testNext1000Puzzles() {
    let seedInt = parseInt(seed);
    for (let i = 0; i < 1000; i++) {
        rng = gen.create(String(seedInt+i));
        let letterGrid = generateLetterGrid();
        
        let solutionChains = [];
        checkSolutionChain([], letterGrid, solutionChains, 6);
        console.log(`+${seedInt+i}: \t${solutionChains.length} solutions`);
    }
}

function clearBoard() {
    $('.tile').remove();
    tileIdMap = {};
    tiles = [];
    currentChain = [];
    currentWord = '';
    history = [];
    turnsTaken = 0;
    tilesLeft = 0;
    currentScore = 0;
    gameComplete = false;
    undoUsed = true;
    $("#solutionLabel").html("");
}

function calculateTilePositions() {
    let letterContainer = $('#letter-container');
    let boardWidth = letterContainer.width();
    let boardHeight = letterContainer.height();
    let boardSize = Math.min(boardWidth, boardHeight);

    let tileSize = (boardSize - 40) / 5;

    let columnCount = tiles.length;
    let tilesWidth = columnCount * tileSize + (columnCount - 1) * 10;
    let xOffset = (boardWidth - tilesWidth) * 0.5;

    let maxColumnHeight = 0;
    for (let i = 0; i < tiles.length; i++) {
        maxColumnHeight = Math.max(maxColumnHeight, tiles[i].length);
    }
    let tilesHeight = maxColumnHeight * tileSize + (maxColumnHeight - 1) * 10;
    let yOffset = (boardHeight - tilesHeight) * 0.5;

    for (let i = 0; i < tiles.length; i++) {
        let column = tiles[i];
        for (let j = 0; j < column.length; j++) {
            let tile = column[j];
            
            tile.left = xOffset + (tileSize + 10) * i;
            tile.bottom = yOffset + (tileSize + 10) * j;
            tile.width = tileSize-4;
            tile.column = i;
            tile.row = j;
        }
    }
}

function updateTilePositions(animate) {
    for (let i = 0; i < tiles.length; i++) {
        let column = tiles[i];
        for (let j = 0; j < column.length; j++) {
            let tile = column[j];
            let element = tile.element;
            $( element ).animate({
                left: tile.left,
                bottom: tile.bottom,
                width: tile.width,
                height: tile.width
            }, animate ? 500 : 0);
        }
    }
}

window.onresize = (event) => {
    calculateTilePositions();
    updateTilePositions();
}

window.addEventListener('load', () => {
    updateTileLetters();
});

$('#confirmSeedBtn').click(() => {
    seed = $('#seed-input').val();
    updateTileLetters();
});

$('#showSolutionBtn').click(() => {
    let solution = findSolution(6, 12);

    let solutionLabel = $("#solutionLabel");
    if (solution == undefined) {
        solutionLabel.html("No solutions available");
        return;
    }
    let solutionText = "";
    for (let i = 0; i < solution.length; i++) {
        const availableWord = solution[i];
        solutionText += availableWord.word;
        if (i < solution.length - 1) {
            solutionText += ", ";
        }
    }

    solutionLabel.html(solutionText);
});

// function loadCurrentSeedFromList() {
//     if (currentSeedIndex < seedList.length) {
//         let currentSeed = seedList[currentSeedIndex];
//         seed = currentSeed[0];
//         let difficulty = currentSeed[1];

//         $("#currentSeedLabel").html(`Seed: ${seed}. Difficulty: ${difficulty}`);
//     } else {
//         $("#currentSeedLabel").html("No more seeds in list");
//     }
// }

// $('#nextSeedBtn').click(() => {
//     currentSeedIndex++;
//     loadCurrentSeedFromList();
//     updateTileLetters();
// });

let wordColours = {
    2: '🟧',
    3: '🟨',
    4: '🟨',
    5: '🟩',
    6: '🟩',
    7: '🟩',
    8: '❇️',
    9: '✳️',
    10: '⚛️',
    11: '🔥',
    12: '🎉'
}
function generateSharingText() {
    let tilesLeftText = `Attempt ${attemptNumber+1}. `;
    if (tilesLeft == 0) {
        tilesLeftText += 'Full clear';
    } else if (tilesLeft == 1) {
        tilesLeftText += '1 tile left';
    } else {
        tilesLeftText += (tilesLeft + ' tiles left');
    }
    let text = `Wordago.net #${seed} Score: ${currentScore}\n${tilesLeftText}`;

    for (let i = 0; i < history.length; i++) {
        text += '\n'
        const wordHistory = history[i];
        let colourCount = Math.min(wordHistory.length, 12);
        let colour = wordColours[colourCount];
        for (let i = 0; i < wordHistory.length; i++) {
            text += colour;
        }
    }
    return text;
}

function generateHistoryURL() {
    let query = 'wordago.net?s=' + seed;
    query += '&h='
    for (let i = 0; i < history.length; i++) {
        const wordHistory = history[i];

        i > 0 && (query += '-');
        for (let j = 0; j < wordHistory.length; j++) {
            const letterHistory = wordHistory[j];
            let letterCode = 65 + letterHistory.oY + (letterHistory.oX * 5);
            query += String.fromCharCode(letterCode);
        }
    }

    return query;
}

function decodeHistory(historyString) {
    let his = [];
    let words = historyString.split('-');

    for (let i = 0; i < words.length; i++) {
        let wordHis = [];
        const word = words[i];
        for (let j = 0; j < word.length; j++) {
            const letterIndex = word.charCodeAt(j) - 65;
            let oY = letterIndex % 5;
            let oX = (letterIndex - oY) / 5;
            wordHis.push({oX: oX, oY: oY});
        }
        his.push(wordHis);
    }
    return his;
}

$('#share-btn').click(() => {
    gtag('event', 'share_pressed', { score : currentScore, turns : turnsTaken, tilesLeft : tilesLeft, attemptNumber: attemptNumber });
    let text = generateSharingText();

    try {
        let shareData = {text: text};
        if (navigator.canShare(shareData)) {
            navigator.share(shareData);
            return;
        }
    } catch (err) {
        
    }

    navigator.clipboard.writeText(text);
    Toastify({text: "Copied result to clipboard", position: "center"}).showToast();

    let his = generateHistoryURL();
    console.log('history: ' + his);
    // decodeHistory(his);
});

$('#retry-btn').click(() => {
    gtag('event', 'retry_pressed', { score : currentScore, turns : turnsTaken, tilesLeft : tilesLeft, attemptNumber: attemptNumber });
    Toastify({text: "Note: Retries do not affect your 1st try statistics.", position: "center"}).showToast();
    attemptNumber++;
    turnsTaken = 0;
    currentScore = 0;
    history = [];
    undoUsed = true;
    gameComplete = false;
    saveGame();

    updateTileLetters();

    hideStatisticsModal();
});

$('#close-stats-btn').click(() => {
    hideStatisticsModal();
});

let postNameTextInput = $('#post-name')
postNameTextInput.on('keydown', (e) => {
    e.stopPropagation();
});

postNameTextInput.on('keyup', (e) => {
    e.stopPropagation();
    let name = postNameTextInput.val();
    $('#post-btn').css('opacity', name.length == 0 ? 0.5 : 1)
    
    if (e.code === 'Enter' || e.keyCode === 13) {
        postScore(name)
    }
});

$('#post-btn').click(() => {
    let name = postNameTextInput.val();
    postScore(name)
    getHighScorePosition(currentScore)
});

function getHighScorePosition(score) {
    const auth = getAuth();
    signInAnonymously(auth)
    .then(() => {
        try {
            const q = query(collection(db, "scores"), where("seed", "==", seed), where("score", ">=", score));

            const snapshot = getCountFromServer(q);
            snapshot.then((snapshot) => {
                let position = snapshot.data().count + 1
                
            })
            
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    })
    .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
    });
}

function postScore(name) {

    if (name.length == 0) {
        return;
    }

    localStorage.setItem('name', name);

    gtag('event', 'posting_score', { score : currentScore, turns : turnsTaken, tilesLeft : tilesLeft, attemptNumber: attemptNumber });

    $('#loading-overlay').css('display', 'flex')

    // sign in anonymously.
    const auth = getAuth();
    signInAnonymously(auth)
    .then(() => {
        try {
            let wordCounts = [];
            for (let i = 0; i < history.length; i++) {
                const wordHistory = history[i];
                wordCounts.push(wordHistory.length)
            }

            setDoc(doc(db, "scores", Timestamp.now().toString()), {//, auth.currentUser.uid + seed), {
                seed: seed,
                name: name,
                score: currentScore,
                wordCounts: wordCounts,
                historyUrl: generateHistoryURL(),
                author_uid: auth.currentUser.uid
            }).then(() => {
                $('#loading-overlay').css('display', 'none')
            });
            
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
    });
}

function getHighScores(seed) {
    // sign in anonymously.
    const auth = getAuth();
    signInAnonymously(auth)
    .then(() => {
        try {
            const q = query(collection(db, "scores"), where("seed", "==", seed));

            const querySnapshot = getDocs(q);
            querySnapshot.then((snapshot) => {
                snapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    });
            })
            
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    })
    .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
    });
}

$('#undoBtn').click(() => {
    gtag('event', 'undo_pressed', { score : currentScore, turns : turnsTaken, tilesLeft : tilesLeft, attemptNumber: attemptNumber });
    let lastWordHistory = history.pop();
    if (lastWordHistory) {
        lastWordHistory.sort((a, b) => {
            if (a.x == b.x) {
                return a.y - b.y;
            }
            return a.x - b.x;
        });
        let addedTiles = [];
        // run through and insert missing columns first
        for (let i = 0; i < lastWordHistory.length; i++) {
            const letterHistory = lastWordHistory[i];
            if (letterHistory.c != undefined) {
                let column = [];
                tiles.splice(letterHistory.c, 0, column);
            }
        }
        for (let i = 0; i < lastWordHistory.length; i++) {
            const letterHistory = lastWordHistory[i];
            let tile = addTile(letterHistory.l, letterHistory.oX, letterHistory.oY, (i+1) * 100);
            tile.column = letterHistory.x;
            tile.row = letterHistory.y;

            tiles[tile.column].splice(tile.row, 0, tile);
            addedTiles.push(tile);
        }
        setupTileListeners();
        calculateTilePositions();

        // position new tiles in correct location
        for (let i = 0; i < addedTiles.length; i++) {
            let tile = addedTiles[i];
            let element = tile.element;
            $( element ).css({
                left: tile.left,
                bottom: tile.bottom,
                width: tile.width,
                height: tile.width
            });
        }

        updateTilePositions(true);

        turnsTaken--;
        currentScore -= wordScoreChart[Math.min(lastWordHistory.length, 12)];
        updateScore();

        undoUsed = true;
        $('#undoBtn').css({
            'opacity': '0.5',
            'pointer-events': 'none'
        });
    }
});

let muted = false;
function setMuted(mute) {
    muted = mute;

    $('.mute-icon').css('opacity', muted ? '0.5' : '');
    $('#muted-line').css('opacity', muted ? '1' : '0');
    $('#mute-btn-text').html(muted ? 'Unmute' : 'Mute')
}

$('#mute-btn').click(() => {
    setMuted(!muted);
    localStorage.setItem('muted', muted ? 'true' : 'false');
});
setMuted(localStorage.getItem('muted') == 'true');

$('#viewStatsBtn').click(() => {
    showStatisticsModal()
});

$('#leaderboardBtn').click(() => {
    showLeaderboardModal();
});

$('#close-leaderboard-btn').click(() => {
    hideLeaderboardModal();
});

document.addEventListener("contextmenu", (e) => {
    endWord(true);
});

addEventListener('focus', (event) => {
    // TODO: Check if seed has changed and update the board if it has.
 });

 function isLeftMouseDown(e) {
    return e.buttons === undefined ? (e.which & 1) === 1 : e.buttons === 1;
  }

function setupTileListeners (elements) {
    let tileElements = $('.tileCentre');

    tileElements.off();
    
    tileElements.on('mousedown', function (event) {
        if (!isLeftMouseDown(event)) {
            return;
        }
        if (touchDown || touchJustEnded) {
            return;
        }

        if (this.parentElement.id.length == 0) {
            return;
        }

        if (mouseDown) {
            endWord(true);
        }

        wordStarted(this.parentElement);
    });

    tileElements.on('mousemove', function (event) {
        if (this.parentElement.id.length == 0) {
            return;
        }
        wordUpdate(this.parentElement);
    });
}

function wordStarted(tileElement) {
    
    playAudioForLetterCount(1);

    mouseDown = true;
    let tile = tileIdMap[tileElement.id];
    let letter = tile.letter;

    currentChain = [tileElement];
    currentWord = letter;

    $(tileElement).addClass('potential');

    currentWordContainer.css({
        'transition-duration': '0ms',
        'transform': '',
    });
    currentWordContainer.css({
        'transition-duration': '',
    });

    currentWordContainer.removeClass('valid');
    currentWordLabel.html(currentWord);
    currentWordContainer.css('opacity', '1');
}

function wordUpdate(tileElement) {
    if (!mouseDown) {
        return;
    }
    let tile = tileIdMap[tileElement.id];
    let letter = tile.letter;

    // if we are on the second last in the chain, remove the last one.
    // if we are on a tile not in the chain, add it.
    let currentChainIndex = currentChain.indexOf(tileElement);
    if (currentChainIndex == -1) {
        // check if row and column of new tile are within 1 of last tile
        let previousTileElement = currentChain[currentChain.length - 1];
        let previousTile = tileIdMap[previousTileElement.id];

        if (Math.abs(tile.row - previousTile.row) < 2 
        && Math.abs(tile.column - previousTile.column) < 2) {
            currentChain.push(tileElement);
            currentWord += letter;
            wordIsValid = checkWord(currentWord);

            playAudioForLetterCount(currentWord.length);
        } else {
            // this tile is not adjacent to the last tile!
            return;
        }
        
    } else if (currentChainIndex < currentChain.length - 1) {
        for (let i = currentChain.length - 2; i >= currentChainIndex; i--) {
            let lastTile = currentChain[currentChain.length-1];
            $(lastTile).removeClass('potential');
            $(lastTile).removeClass('valid');

            currentChain.pop();
            currentWord =  currentWord.slice(0, -1);
        }
        playAudioForLetterCount(currentWord.length);
        wordIsValid = checkWord(currentWord);
    } else {
        // we haven't moved off the last letter
        return;
    }

    if (wordIsValid) {
        currentChain.forEach(tile => {
            $(tile).removeClass('potential');
            $(tile).addClass('valid');
        });
        currentWordContainer.addClass('valid');
    } else {
        currentChain.forEach(tile => {
            $(tile).addClass('potential');
            $(tile).removeClass('valid');
        });
        currentWordContainer.removeClass('valid');
    }
    currentWordLabel.html(currentWord);
}

letterContainer.on('touchstart', function (event) {
    if (touchDown) {
        return;
    }
    let touch = event.touches[event.touches.length-1];
    let tile = document.elementFromPoint(touch.clientX, touch.clientY);
    if ($(tile).hasClass('tileCentre')) {
        if (tile.parentElement.id.length == 0) {
            return;
        }

        touchDown = event.touches[0];
        wordStarted(tile.parentElement);
    }
    event.preventDefault();
    event.stopPropagation();
});

letterContainer.on('touchmove', function (event) {
    if (!touchDown) {
        return;
    }
    let touch;
    for (let i = 0; i < event.touches.length; i++) {
        const aTouch = event.touches[i];
        if (aTouch.identifier == touchDown.identifier) {
            touch = aTouch;
        }
    }
    if (!touch) {
        return;
    }
    let tile = document.elementFromPoint(touch.clientX, touch.clientY);

    if ($(tile).hasClass('tileCentre')) {
        if (tile.parentElement.id.length == 0) {
            return;
        }
        wordUpdate(tile.parentElement);
    }
    event.preventDefault();
    event.stopPropagation();
});

let touchJustEnded = false;
letterContainer.on('touchend', function (event) {
    if (!touchDown) {
        return;
    }
    for (let i = 0; i < event.touches.length; i++) {
        const aTouch = event.touches[i];
        if (aTouch.identifier == touchDown.identifier) {
            return;
        }
    }

    touchDown = undefined;
    touchJustEnded = true;
    setTimeout(() => {
        touchJustEnded = false;
    }, 100);
    
    endWord();
    event.preventDefault();
    event.stopPropagation();
});

letterContainer.on('touchcancel', function (event) {
    touchDown = false;
    endWord(true);
    event.preventDefault();
    event.stopPropagation();
});

document.onmouseup = function () {
    endWord();
}

export const checkWord = (word) => {
    const lowerCased = word.toLowerCase();
    const possibleWords = wordList[word.length];

    if (possibleWords == undefined) {
        return;
    }

    if (possibleWords[lowerCased] != undefined) {
        return true;
    } else {
        return false;
    }
}

function removeWord(chain, playAudio, duration = 75) {
    let index = 0;
    let wordHistory = [];
    chain.forEach(tileElement => {
        let currentIndex = index;
        let tileJElement = $( tileElement );
        setTimeout(() => {
            tileJElement.css({
                'z-index': chain.indexOf(tileElement),
                'transform': 'matrix(1.4, 0, 0, 1.4, 0, 0)',
            });
            tileJElement.animate({
                opacity: 0,
            }, 500, function() {
                tileJElement.remove();
            });
            if (playAudio) {
                playAudioForLetterCount(currentIndex+1);
            }
        }, index * duration);
        index++;
        
        // remove the tiles from the game.
        let tile = tileIdMap[tileElement.id];
        let tileHistory = tile.snapshot();
        for (let i = 0; i < tiles.length; i++) {
            let column = tiles[i];
            let tileIndex = column.indexOf(tile);
            if (tileIndex != -1) {
                column.splice(tileIndex, 1);
            }
            if (column.length == 0) {
                tileHistory.c = i; // remember the index of the deleted column
                tiles.splice(i, 1);
            }
            if (tileIndex != -1) {
                break;
            }
        }
        wordHistory.push(tileHistory);
        undoUsed = false;
        $('#undoBtn').css({
            'opacity': '1',
            'pointer-events': 'all'
        });
        tileJElement.attr('id', '');
        tileJElement.css('pointer-events', 'none');
    });
    return wordHistory;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

function showEncouragement(text) {
    $("#end-text").html(text);
    $("#end-text-container").css('display', '');
    $("#end-text-wrapper").fadeIn();
}

function hideEncouragement() {
    $("#end-text-container").hide();
    $("#end-text-wrapper").hide();
}

function endWord(cancelled = false) {
    mouseDown = false;
    currentChain.forEach(tileElement => {
        $(tileElement).removeClass('potential');

        if (cancelled) {
            $(tileElement).removeClass('valid');
        }
    });

    if (wordIsValid && !cancelled) {
        currentWordContainer.css({
            'opacity': '0',
            'transform': 'scale(1.5)'
        });

        turnsTaken += 1;
        tilesLeft -= currentChain.length;
        currentScore += wordScoreChart[Math.min(currentChain.length, 12)];

        gtag('event', 'end_word', { letters : currentChain.length, turnNumber : turnsTaken });
        
        let wordHistory = removeWord(currentChain, true);
        history.push(wordHistory);
        setTimeout(() => {
            calculateTilePositions();
            updateTilePositions(true);
        }, 500);
        
        if (tilesLeft == 0) {
            setTimeout(() => {
                playSuccessAudio();
                const encouragementOptions = ['Perfect!', 'Full Clear!', 'Fantastic!'];
                showEncouragement(encouragementOptions[getRandomInt(encouragementOptions.length)]);
            }, 1000);

            gameComplete = true;
            currentScore += perfectClearBonus;

            gtag('event', 'game_finished', { score : currentScore, turns : turnsTaken, tilesLeft : 0, attemptNumber: attemptNumber });

            if (attemptNumber == 0) {
                addWinToScoreSheet(turnsTaken);
            }
        } else if (tilesLeft < 12) {
            let wordCount = numberOfPossibleWords();
            if (!wordCount) {
                setTimeout(() => {
	                const encouragementOptions = ['So Close!', 'Nearly!'];
	                showEncouragement(encouragementOptions[getRandomInt(encouragementOptions.length)]);
                }, 1000);    
                gameComplete = true;
                currentScore -= tilesLeft * scorePenaltyPerTile;

                gtag('event', 'game_finished', { score : currentScore, turns : turnsTaken, tilesLeft : tilesLeft, attemptNumber: attemptNumber });

                if (attemptNumber == 0) {
                    addLossToScoreSheet();
                }
            } else {
                console.log("Possible words: " + wordCount);
            }
        }

        updateScore();

        saveGame();

        if (gameComplete) {
            setTimeout(() => {
                showStatisticsModal();
                // localStorage.clear();
                setTimeout(() => {
                    hideEncouragement();
                    $('#viewStatsBtn').css({
                        'opacity': '1.0',
                        'pointer-events': 'all'
                    });
                }, 500);
                
            }, 4000);
        }
    } else {
        currentWordContainer.css({
            'opacity': '0',
        });
    }

    currentChain = [];
    currentWord = '';
    wordIsValid = false;
}

function showStatisticsModal() {
    populateStatisticsModal();
    $("#score-modal-background").css("display", "flex").hide().fadeIn();
    let scoreModal = $("#score-modal");
    scoreModal.css({
        'transition-duration': '0ms',
        'transform': 'scale(1.3)',
    });
    scoreModal.css({
        'transition-duration': '',
    });
    scoreModal.css({
        'transform': '',
    });
}

function hideStatisticsModal() {
    $("#score-modal-background").fadeOut();
    let scoreModal = $("#score-modal");
    scoreModal.css({
        'transform': 'scale(1.3)',
    });
}

function showLeaderboardModal() {
    populateLeaderboardModal();
    $("#leaderboard-background").css("display", "flex").hide().fadeIn();
    let scoreModal = $("#leaderboard-modal");
    scoreModal.css({
        'transition-duration': '0ms',
        'transform': 'scale(1.3)',
    });
    scoreModal.css({
        'transition-duration': '',
    });
    scoreModal.css({
        'transform': '',
    });
}

function hideLeaderboardModal() {
    $("#leaderboard-background").fadeOut();
    let scoreModal = $("#leaderboard-modal");
    scoreModal.css({
        'transform': 'scale(1.3)',
    });
}

function populateLeaderboardModal() {

}

function updateScore() {
    $('#scoreLabel').html('Score: ' + String(currentScore));
    $('#turnsTaken').html('Turns: ' + String(turnsTaken));
    $('#tilesLeft').html('Tiles: ' + String(tilesLeft));
}

function getCurrentLetterGrid() {
    let letterGrid =  [];
    for (let i = 0; i < tiles.length; i++) {
        let column = tiles[i];
        let letterColumn = [];
        for (let j = 0; j < column.length; j++) {
            let tile = column[j];
            letterColumn.push(tile.letter);
        }
        letterGrid.push(letterColumn);
    }
    return letterGrid;
}

function numberOfPossibleWords() {
    let letterGrid = getCurrentLetterGrid();

    let availableWords = checkWordGrid(letterGrid);

    return availableWords.length;
}

function findSolution(limit = 6, maxWordLength) {
    let letterGrid = getCurrentLetterGrid();

    let solutionChains = [];
    
    var startTime = performance.now()
    checkSolutionChain([], letterGrid, solutionChains, limit, maxWordLength);

    for (let i = 0; i < solutionChains.length; i++) {
        let chain = solutionChains[i];
        let pointValue = 0;
        for (let j = 0; j < chain.length; j++) {
            const chainLink = chain[j];
            const word = chainLink.word;
            pointValue += wordScoreChart[word.length];
        }
        chain.points = pointValue;
    }

    var endTime = performance.now();
    console.log(`Call took ${endTime - startTime} milliseconds\n\n\n`);

    solutionChains.sort((a, b) => {
        return b.points - a.points;
    });

    console.log("found " + solutionChains.length + " possible solutions");

    return solutionChains[0];
}

//GAME SAVING

function saveGame() {
    if (replayHistory) {
        return;
    }

    let gameState = {
        turnsTaken: turnsTaken,
        currentScore: currentScore,
        seed: seed,
        letterGrid: letterGrid,
        tilesLeft: tilesLeft,
        history: history,
        undoUsed: undoUsed,
        attemptNumber: attemptNumber
    }

    localStorage.setItem('inProgressGame', JSON.stringify(gameState));
    localStorage.setItem('gameComplete', gameComplete);
}

function loadGame() {
    if (replayHistory) {
        return;
    }

    let savedGameJSON = localStorage.getItem('inProgressGame');
    try {
        if (savedGameJSON) {
            return JSON.parse(savedGameJSON);
        }
    } catch (error) {}

    return undefined;
}

function saveToBestToday() {
    if (replayHistory) {
        return;
    }

    let savedGameJSON = localStorage.getItem('inProgressGame');
    if (savedGameJSON) {
        localStorage.setItem('bestGameToday', savedGameJSON);
    }
}

function getBestToday() {
    let savedGameJSON = localStorage.getItem('bestGameToday');
    try {
        if (savedGameJSON) {
            let bestToday = JSON.parse(savedGameJSON);

            if (bestToday.seed != seed) {
                localStorage.removeItem('bestGameToday');
                return undefined;
            }
            return bestToday;
        }
    } catch (error) {}

    return undefined;
}

//STATISTICS

function getStatistics() {
    let statistics;
    try {
        let statsJSON = localStorage.getItem('playstats');
        statistics = JSON.parse(statsJSON);
    } catch (error) { }

    if (statistics == undefined) {
        statistics = {
            gamesPlayed: 0,
            gamesWon: 0,
            gamesLost: 0,
            currentStreak: 0,
            bestStreak: 0,
            bestScore: -100,
            turnCounts: {
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
            }
        };
    }

    return statistics;
}

function addWinToScoreSheet(turnCount) {
    let stats = getStatistics();

    stats.gamesPlayed += 1;
    stats.gamesWon += 1;
    stats.currentStreak += 1;
    stats.bestStreak = Math.max(stats.bestStreak, stats.currentStreak);
    stats.bestScore = Math.max(stats.bestScore, currentScore);
    stats.turnCounts[turnCount] += 1;

    localStorage.setItem('playstats', JSON.stringify(stats));
    localStorage.setItem('gameComplete', true);
}

function addLossToScoreSheet(turnCount) {
    let stats = getStatistics();

    stats.gamesPlayed += 1;
    stats.gamesLost += 1;
    stats.currentStreak = 0;
    stats.bestScore = Math.max(stats.bestScore, currentScore);
    if (turnCount != undefined) {
        stats.turnCounts[turnCount] += 1;
    }

    localStorage.setItem('playstats', JSON.stringify(stats));
    localStorage.setItem('gameComplete', true);
}

function populateStatisticsModal() {

    if (replayHistory) {
        $('#stats-container').hide();
        $('#first-try-divider').hide();
        $('#attempt-label').hide();
        $('#share-footer').hide();
        $('#attempts-container').css('margin-bottom', '20px');
        
    } else {
        // fill out first try stats
        let stats = getStatistics();
        if (stats && stats.gamesPlayed) {
            $('#stats-container').show();
            $('#play-count').html(stats.gamesPlayed);
            let percentWon = Math.round(100 * stats.gamesWon / stats.gamesPlayed);
            $('#percent-win').html(percentWon);
            $('#current-streak').html(stats.currentStreak);
            $('#best-score').html(stats.bestScore);
        } else {
            $('#stats-container').hide();
        }
    }
    
    if (gameComplete) {
        $('#this-attempt-container').show();

        // fill out current attempt stats
        $('#score-label').html('SCORE: ' + currentScore);
        $('#attempt-label').html(`Attempt ${attemptNumber+1}`);

        let foundWordContainer = $('#found-words-container');
        foundWordContainer.empty();
        for (let i = 0; i < history.length; i++) {
            const wordHistory = history[i];
            
            let word = '';
            for (let i = 0; i < wordHistory.length; i++) {
                const letterHistory = wordHistory[i];
                word += letterHistory.l;
            }
            foundWordContainer.append($(`<div class="stats-found-word">${word}</div>`));
        }

        $('#close-stats-btn').hide();
        $('#share-btn').show();
        $('#retry-btn').show();
        $('#post-to-leaderboard-container').show();
    } else {
        $('#this-attempt-container').hide();
        $('#close-stats-btn').show();
        $('#share-btn').hide();
        $('#retry-btn').hide();
        $('#post-to-leaderboard-container').hide();
    }
    

    // fill out best today stats
    let bestToday = !!replayHistory ? undefined : getBestToday();
    // if we don't have a 'best today' or if the current score is better, overwrite it.
    if (!replayHistory && (bestToday == undefined || bestToday.currentScore < currentScore)) {
        saveToBestToday();
        bestToday = getBestToday();
    }
    // only show best today if it's different try
    if (!replayHistory && bestToday.attemptNumber != attemptNumber) {
        $('#best-attempt-container').css('display', '');
        $('#best-attempt-score').html('SCORE: ' + bestToday.currentScore);
    
        let foundWordContainer = $('#best-found-words-container');
        foundWordContainer.empty();
        for (let i = 0; i < bestToday.history.length; i++) {
            const wordHistory = bestToday.history[i];
            
            let word = '';
            for (let i = 0; i < wordHistory.length; i++) {
                const letterHistory = wordHistory[i];
                word += letterHistory.l;
            }
            foundWordContainer.append($(`<div class="stats-found-word">${word}</div>`));
        }
    } else {
        $('#best-attempt-container').hide();
        if (attemptNumber) {
            $('#attempt-label').html(`Attempt ${attemptNumber+1} (New best today!)`);
        } else {
            $('#attempt-label').html(`Attempt ${attemptNumber+1}`);
        }
        
    }

    let name = localStorage.getItem('name')
    if (name && name.length > 0) {
        postNameTextInput.val(name)
        $('#post-btn').css('opacity', 1)
    }
    
}

let clickCount = 0;
let clearStatsTimeout;
$('#stats-container').click(() => {
    clickCount++;
    if (clearStatsTimeout) {
        clearTimeout(clearStatsTimeout);
    }

    if (clickCount == 5) {
        gtag('event', 'stats_cleared');

        clearStatsTimeout = undefined;
        localStorage.clear();
        Toastify({text: "Statistics reset", position: "center"}).showToast();

        attemptNumber = 0;
        turnsTaken = 0;
        currentScore = 0;
        history = [];
        undoUsed = true;
        gameComplete = false;
        saveGame();
        updateTileLetters();
        hideStatisticsModal();

    } else {
        clearStatsTimeout = setTimeout(() => {
            clickCount = 0;
        }, 1000);
    }
});

// SOUND EFFECTS

let successAudioBuffer = null;
let letterAudioBuffers = [];
let audioURLs = [letterAudio1, letterAudio2, letterAudio3, letterAudio4, letterAudio5, letterAudio6, letterAudio7, letterAudio8];

try {
    window.audioContext = window.audioContext || new AudioContext();
} catch (error) {
    console.log("AudioContext not supported", error);
}
var audioContext = window.audioContext;

function loadAudioFiles() {
    let promiseList = [];
    for (let i = 0; i < audioURLs.length; i++) {
        let promise = fetch(audioURLs[i]).then(response => response.arrayBuffer()).then(array_buffer => audioContext.decodeAudioData(array_buffer))
        promiseList.push(promise);
    }
    Promise.all(promiseList).then((values) => {
        letterAudioBuffers = values;
    });

    let promise = fetch(successAudio).then(response => response.arrayBuffer()).then(array_buffer => audioContext.decodeAudioData(array_buffer))
    promise.then((audioBuffer) => successAudioBuffer = audioBuffer);
}

function queueAudioBufferForLetterCount(letterCount) {
    let index = Math.min(letterCount, letterAudioBuffers.length);
        
    const source = audioContext.createBufferSource();
    source.buffer = letterAudioBuffers[index-1];
    source.connect(audioContext.destination);
    source.start();
}

function playAudioForLetterCount(letterCount) {
    if (muted) {
        return;
    }
    if (audioContext) {
        if (audioContext.state == 'running') {
            queueAudioBufferForLetterCount(letterCount);
        } else {
            let queueTime = new Date();
            audioContext.resume().then(() => {
                if (audioContext.state == 'running') {
                    let timeNow = new Date();
                    let timePassed = timeNow - queueTime;
                    if (timePassed > 100) {
                        return;
                    }
                    queueAudioBufferForLetterCount(letterCount);
                }
            });
        }
    }
}

function playSuccessAudio() {
    if (muted) {
        return;
    }

    if (audioContext && successAudioBuffer) {
        audioContext.resume().then(() => {
            if (audioContext.state == 'running') {
                const source = audioContext.createBufferSource();
                source.buffer = successAudioBuffer;
                source.connect(audioContext.destination);
                source.start();
            }
        });
    }
}

if (audioContext) {
    loadAudioFiles();
}

//INTRO MODAL

let introModalBackground = $('#intro-modal-background');
function showIntroModal() {
    introModalBackground.css("display", "flex").hide().fadeIn();
    let introModal = $("#intro-modal");
    introModal.css({
        'transition-duration': '0ms',
        'transform': 'scale(1.3)',
    });
    introModal.css({
        'transition-duration': '',
    });
    $( introModal ).css({
        'transform': '',
    });

    setTimeout(() => {
        $('#intro-video')[0].play();
    }, 500);
}

function hideIntroModal() {
    introModalBackground.fadeOut();
    let introModal = $("#intro-modal");
    introModal.css({
        'transform': 'scale(1.3)',
    });

    setTimeout(() => {
        introModalBackground.remove();
    }, 500);
    localStorage.setItem('intro-Viewed', 'true');
}

if (replayHistory || localStorage.getItem('intro-Viewed') != undefined) {
    introModalBackground.remove();
} else {
    let introVideo = $('#intro-video');
    introVideo[0].oncanplay = function () {
        showIntroModal();
        introVideo[0].oncanplay = undefined;
   };
   introVideo[0].load();
}

$('#dismiss-instruction-btn').click(() => {
    hideIntroModal();
});
export let seedList = [
    ['22', '8650'],
    ['241', '3720'],
    ['111', '2738'],
    ['104', '1965'],
    ['246', '1337'],
    ['38', '1039'],
    ['156', '882'],
    ['214', '752'],
    ['224', '670'],
    ['338', '576'],
    ['29', '445'],
    ['146', '380'],
    ['765', '280'],
    ['120', '213'],
    ['582', '166'],
    ['310', '91'],
    ['60', '75'],
    ['883', '45'],
    ['620', '9'],
    ['664', '8']
]